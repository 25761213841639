
import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, Avatar, Button, Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Menu as MenuIcon, Logout as LogoutIcon } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from './images/logo.png';
import './Navbar.css';

const Navbar = () => {
  const [user, setUser] = useState(null);
  const [tokens, setTokens] = useState(null);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null); // For avatar dropdown
  const [mobileMenuAnchor, setMobileMenuAnchor] = useState(null); // For mobile menu

  const token = localStorage.getItem('authToken');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchUser = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/user`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });
        if (response.ok) {
          const userData = await response.json();
          setUser(userData);
        } else {
          console.error('Failed to fetch user data:', response.statusText);
        }
      } catch (error) {
        console.error('Failed to fetch user data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [token]);

  useEffect(() => {
    let timeoutId;

    const handleActivity = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(handleLogout, 15 * 60 * 1000); // 15 minutes in milliseconds
    };

    const handleLogout = () => {
      localStorage.removeItem('authToken');
      window.location.href = '/';
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keypress', handleActivity);

    timeoutId = setTimeout(handleLogout, 15 * 60 * 1000);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keypress', handleActivity);
    };
  }, []);

  const handleLogout = async () => {
    localStorage.removeItem('authToken');
    navigate(0);
    // window.location.href = '/';
  };

  const handleGoogleAuth = () => {
    // Open a new popup window for Google authentication
    const width = 500;
    const height = 600;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    const googleAuthPopup = window.open(
      `${process.env.REACT_APP_BACKEND_URL}/auth/google`,
      'GoogleLogin',
      `width=${width},height=${height},top=${top},left=${left}`
    );

    // Set up a listener to receive the token from the popup window
    const receiveMessage = (event) => {
      if (event.origin !== process.env.REACT_APP_BACKEND_URL) return; // Ensure it's coming from the right origin
      const { token, error } = event.data;

      if (error) {
        // Handle the error (e.g., display a notification)
        alert(error); // Replace with your preferred notification method
        return;
      }

      if (token) {
        // Store the token in localStorage and refresh the main window
        localStorage.setItem('authToken', token);
        window.location.reload(); // Refresh to get user data
      }
    };

    window.addEventListener('message', receiveMessage, false);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  };

  const navigateHome = () => {
    window.location.href = '/';
  };

  const fetchTokens = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/user/tokens`, {
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch tokens');
      }

      const data = await response.json();
      setTokens({
        tokensAlloted: data.tokensAlloted,
        tokensUsed: data.tokensUsed,
        balanceToken: data.balanceToken,
      });
    } catch (error) {
      console.error('Error fetching tokens:', error);
    }
  };

  const toggleDropdown = (event) => {
    setAnchorEl(event.currentTarget);
    fetchTokens();
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMenuAnchor(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuAnchor(null);
  };

  if (loading) {
    return (
      <AppBar position="static" sx={{ height: '60px', backgroundColor: 'white', padding: '0' }}>
        <Toolbar sx={{ height: '60px' }}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <span className='logo-text' onClick={()=>navigate('/dashboard')}> DIITTOO </span>
          </Typography>
          <CircularProgress color="inherit" />
        </Toolbar>
      </AppBar>
    );
  }

  return (
    <AppBar position="fixed" sx={{ height: '61px', backgroundColor: 'white', color: 'black', padding: '0', }}>
      <Toolbar sx={{ height: '60px', margin: '0', display: 'flex', padding:'0'}}>

        {/* Desktop Menu */}

        <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'space-between', flexGrow: '1',}}>
          <Typography variant="h6" component="div" sx={{ marginLeft: '20px', flexGrow: { xs: 1, md: 0 } }} className='logo'>
            <span className='logo-text' onClick={()=>navigate('/dashboard')}> DIITTOO </span>
          </Typography>
          <Box >
            {/* User Dropdown or Login Button */}
            {user ? (
              <IconButton onClick={toggleDropdown} sx={{ marginLeft: 'auto'  }}  disableRipple>
                <Avatar alt={user.name} src={user.picture} />
                <Typography sx={{ marginLeft: '15px', display: { xs: 'none', md: 'inline' } }}>{user.name}</Typography>
              </IconButton>
            ) : (
              <button variant="outlined" onClick={handleGoogleAuth} sx={{ marginLeft: 'auto' }} className='beta-button'>Login with Google</button>
            )}
          </Box>
        </Box>


        {/* Mobile Menu Icon */}

        <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'space-between', flexGrow: 1, alignItems: 'center' }}>
          <Typography variant="h6" component="div" sx={{ marginLeft: '20px' }} className='logo'>
            {/* <img src={logo} style={{ width: '90px', height: '40px', marginRight: '10px' }} alt="" /> */}
            <span className='logo-text'> DIITTOO </span>
          </Typography>

          {/* Menu Icon aligned to the right */}
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            sx={{ display: { xs: 'flex', md: 'none' , } }}
            onClick={handleMobileMenuOpen}
          >
            <MenuIcon style={{ paddingRight: '5px' }} />
          </IconButton>
        </Box>

        {/* Mobile Menu */}
        <Menu
          anchorEl={mobileMenuAnchor}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          open={Boolean(mobileMenuAnchor)}
          onClose={handleMobileMenuClose}
        >
          {user ? (
            <>
              <MenuItem  >
                <Avatar alt={user.name} src={user.picture} sx={{ marginRight: '10px' }} />
                {user.name}
              </MenuItem>
            </>
          ) : (
            <MenuItem onClick={handleGoogleAuth}>
              Login with Google
            </MenuItem>
          )}
          <MenuItem onClick={toggleDropdown} >Token Details </MenuItem>
          <MenuItem onClick={handleLogout}><LogoutIcon fontSize="small" /> Logout</MenuItem>
        </Menu>

        {/* User Dropdown Menu */}
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseDropdown}>
          {tokens ? (
            <>
              <MenuItem>Tokens Alloted: {tokens.tokensAlloted}</MenuItem>
              <MenuItem>Tokens Used: {tokens.tokensUsed}</MenuItem>
              <MenuItem>Balance Token: {tokens.balanceToken}</MenuItem>
            </>
          ) : (
            <MenuItem>Loading tokens...</MenuItem>
          )}
          <MenuItem onClick={handleLogout}>
            <LogoutIcon fontSize="small" /> Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );

};


export default Navbar;
