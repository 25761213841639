import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import './ModalDelete.css';

// Create a custom Alert component with your desired color
const CustomAlert = styled(MuiAlert)(({ theme }) => ({
    backgroundColor: '#0072FF', // Set your custom background color
    color: 'white', // Ensure text color is white for contrast
}));

const ModalDelete = ({ modalDelete, setModalDelete, docToDelete, setDocToDelete, token, fetchDocuments }) => {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const handleDeleteDocs = async (docToDelete) => {
        setModalDelete(false); 
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/delete-document`,
                {
                    docId: docToDelete._id,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    withCredentials: true,
                }
            );

            if (response.status === 200) {
                setSnackbarMessage('Document deleted successfully!');
                setOpenSnackbar(true);
                fetchDocuments(); 
            } else {
                setSnackbarMessage('Failed to delete document. Please try again.'); 
                setOpenSnackbar(true);
            }
        } catch (error) {
            setSnackbarMessage('Error deleting document.');
            setOpenSnackbar(true);
            // console.log(error);
        } finally {
            setDocToDelete(null);
            setModalDelete(false); 
        }
    };

    const handleCancel = () => {
        setDocToDelete(null);
        setModalDelete(false); 
    };

    return (
        <>
            <Modal show={modalDelete} onHide={handleCancel} style={{ marginTop: '70px' }}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete the document : <br/> <strong>{docToDelete?.title}</strong>?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button variant="secondary" onClick={handleCancel}>
                        No
                    </button>
                    <button variant="danger" onClick={() => handleDeleteDocs(docToDelete)}>
                        Yes
                    </button>
                </Modal.Footer>
            </Modal>

            <Snackbar open={openSnackbar} autoHideDuration={1000} onClose={handleCloseSnackbar}>
                <CustomAlert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </CustomAlert>
            </Snackbar>
        </>
    );
};

export default ModalDelete;
