import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Checkbox, FormControlLabel, Button, Typography, Container, Box, Snackbar, Alert, CircularProgress, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import './BetaFormHr.css';

const BetaProgramForm = ({ isActive }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');
  const [agree, setAgree] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const submissionData = {
        googleEmail: data.email,
        name: data.name,
        niche: data.niche,
        linkedinProfile: data.linkedin,
        privacyPolicy: data.agree,
        motivation: data.motivation,
      };

      setMessage('');
      setMessageColor('');

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/askforaccess`, submissionData);

      if (response.status === 201) {
        setMessage('Thanks! Your application to become a Beta User has been submitted. Please check your email for further action.');
        setMessageColor('green');
      }
    } catch (error) {
      setMessageColor('red');
      if (error.response && error.response.data && error.response.data.message) {
        setMessage(error.response.data.message);
      } else {
        setMessage('An unexpected error occurred. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container component="main"  className={`beta-form-hr ${isActive ? 'active' : ''}`}
      sx={{  width: '90%'}}
      fullWidth
      style={{
        paddingLeft: '0px',
        paddingRight: '0px',
        marginTop: '30px',
        marginBottom: '50px',
        marginLeft: '5%',
        marginRight: '5%',
        // border:'2px solid yellow'
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          mt: 0,
          mx: 0,
          p: 3,
          borderRadius: '10px',
          // background: '#F3F6F8',
          background: 'white',
          // border:'2px solid red',
          width: '100%',
          boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography variant="h5" gutterBottom style={{ color: '#616263' }}>
          Join the Beta Program
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          <Grid container spacing={2} style={{ width: '100%', boxSizing: 'border-box' }}>
            <Grid item xs={12} sm={3} style={{ width: '25%' }}>  {/* Changed to 25% */}
              <TextField
                {...register('email', { required: 'Email is required' })}
                label="Google Email Address"
                variant="outlined"
                fullWidth
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : ''}
                InputProps={{ style: { boxSizing: 'border-box' } }} // Ensures padding is included in the width
              />
            </Grid>
            <Grid item xs={12} sm={3} style={{ width: '25%' }}>  {/* Changed to 25% */}
              <TextField
                {...register('name', { required: 'Name is required' })}
                label="Name"
                variant="outlined"
                fullWidth
                error={!!errors.name}
                helperText={errors.name ? errors.name.message : ''}
                InputProps={{ style: { boxSizing: 'border-box' } }} // Ensures padding is included in the width
              />
            </Grid>
            <Grid item xs={12} sm={3} style={{ width: '25%' }}>  {/* Changed to 25% */}
              <TextField
                {...register('niche', { required: 'Niche is required' })}
                label="Niche"
                variant="outlined"
                fullWidth
                error={!!errors.niche}
                helperText={errors.niche ? errors.niche.message : ''}
                InputProps={{ style: { boxSizing: 'border-box' } }} // Ensures padding is included in the width
              />
            </Grid>
            <Grid item xs={12} sm={3} style={{ width: '25%' }}>  {/* Changed to 25% */}
              <TextField
                {...register('linkedin', { required: 'LinkedIn Profile Link is required' })}
                label="LinkedIn Profile Link"
                variant="outlined"
                fullWidth
                error={!!errors.linkedin}
                helperText={errors.linkedin ? errors.linkedin.message : ''}
                InputProps={{ style: { boxSizing: 'border-box' } }} // Ensures padding is included in the width
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 2 }} style={{boxSizing: 'border-box',width:'100%'}}>
            <Grid item xs={12} sm={8}>
              {
                message ?
                  (
                    <Typography style={{ color: messageColor }}>{message}</Typography>
                  ) : (
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox {...register('agree', { required: true })}
                            color="primary"
                          />
                        }
                        label={
                          <span style={{ color: '#616263' }}>
                            I agree to be contacted by Diittoo team to collect feedback by email or personal messages.
                          </span>
                        }
                      />
                      {errors.agree && (
                        <Typography color="error" variant="body2" >
                          You must agree to continue.
                        </Typography>
                      )}
                    </>
                  )
              }
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default BetaProgramForm;