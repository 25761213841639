
import React, { useRef, useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './Editor.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo, faSpinner } from '@fortawesome/free-solid-svg-icons';
import ReactMarkdown from 'react-markdown';

const modules3 =  {
  toolbar: {
    container: '#toolbar',
  },
  keyboard: {
    bindings: {
      bold: {
        key: 'B',
        shortKey: true,
        handler: () => {},
      },
      italic: {
        key: 'I',
        shortKey: true,
        handler: () => {},
      },
      underline: {
        key: 'U',
        shortKey: true,
        handler: () => {},
      },
      // Add more bindings you want to disable here
    },
  },
};


const tones = ['Formal', 'Casual', 'Friendly', 'Assertive', 'Optimistic','Negative']; // Add more tones as needed

const Editor = ({ editorContent, setEditorContent, quillRef}) => {
  // const quillRef = useRef(null);
  const iconContainerRef = useRef(null);
  const [showIcons, setShowIcons] = useState(false);
  const [iconPosition, setIconPosition] = useState({ top: 0, left: 0 });
  const [loading, setLoading] = useState(false);
  const [toneLoading, setToneLoading] = useState(false); // Loading state for tone change
  const [regeneratedText, setRegeneratedText] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);

  const handleMouseUp = () => {
    const editor = quillRef.current.getEditor();
    const selection = editor.getSelection();

    if (selection && selection.length > 0) {
      const bounds = editor.getBounds(selection);
      setIconPosition({
        top: bounds.top - 40,
        left: bounds.left + bounds.width / 2 - 15,
      });
      setShowIcons(true);
    } else {
      setShowIcons(false);
    }
  };

  const handleChangeToneClick = () => {
    setShowDropdown(!showDropdown); // Toggle dropdown visibility
  };

  const handleToneSelect = async (tone) => {
    const editor = quillRef.current.getEditor();
    const selection = editor.getSelection();

    if (selection && selection.length > 0) {
      const selectedText = editor.getText(selection.index, selection.length);
      setToneLoading(true); // Set loading for tone change
      const token = localStorage.getItem('authToken');
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/changeTone`, // Call the change tone API
          { text: selectedText, tone: tone }, // Send the selected text and tone
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        setRegeneratedText(response.data.content); // Set the modified text with the new tone
      } catch (error) {
        console.error('Failed to change tone:', error);
      } finally {
        setToneLoading(false); // Reset loading for tone change
      }
    }
    setShowDropdown(false); // Hide dropdown after selection
  };

  const handleRegenerateClick = async () => {
    const editor = quillRef.current.getEditor();
    const selection = editor.getSelection();

    if (selection && selection.length > 0) {
      const selectedText = editor.getText(selection.index, selection.length);
      setLoading(true);
      const token = localStorage.getItem('authToken');
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/regenerate`, // Call the regenerate API
          { text: selectedText },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        setRegeneratedText(response.data.content); // Set the regenerated text
      } catch (error) {
        console.error('Failed to regenerate:', error);
      } finally {
        setLoading(false); // Reset loading for regeneration
      }
    }
  };

  const handleReplaceClick = () => {
    if (regeneratedText) {
      const editor = quillRef.current.getEditor();
      const selection = editor.getSelection();

      if (selection && selection.length > 0) {
        editor.deleteText(selection.index, selection.length);
        editor.insertText(selection.index, regeneratedText);
        setRegeneratedText('');
        setShowIcons(false);
      }
    }
  };

  const handleCancelClick = () => {
    setRegeneratedText('');
    setShowIcons(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (iconContainerRef.current && !iconContainerRef.current.contains(event.target)) {
        handleCancelClick();
        setShowDropdown(false); // Hide dropdown if clicked outside
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div onMouseUp={handleMouseUp} style={{ position: 'relative' }}>
      <ReactQuill
        ref={quillRef}
        value={editorContent}
        onChange={setEditorContent}
        theme="snow"
        modules={modules3}
        className="react-quill-editor"
      />
      {showIcons && (
        <div
          ref={iconContainerRef}
          style={{
            position: 'absolute',
            top: iconPosition.top,
            left: iconPosition.left,
            zIndex: 1000,
          }}
        >
          <div
            style={{
              backgroundColor: '#ffffff',
              border: '1px solid #ccc',
              borderRadius: '8px',
              padding: '5px',
              display: 'flex',
              gap: '5px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
          >
            <div
              style={{
                padding: '5px',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#f0f0f0',
              }}
              onMouseDown={(e) => {
                e.preventDefault();
                handleRegenerateClick(); // Call the regenerate function
              }}
            >
              <FontAwesomeIcon icon={loading ? faSpinner : faRedo} spin={loading} /> &nbsp; Regenerate
            </div>
            <div
              style={{
                padding: '5px',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#f0f0f0',
              }}
              onMouseDown={(e) => {
                e.preventDefault();
                handleChangeToneClick(); // Call the change tone function
              }}
            >
              Change Tone {toneLoading && <FontAwesomeIcon icon={faSpinner} spin />} {/* Loading for Change Tone button */}
            </div>
          </div>

          {showDropdown && (
            <div style={{
              position: 'absolute',
              backgroundColor: '#fff',
              border: '1px solid #ccc',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              zIndex: 1000,
              marginTop: '5px',
            }}>
              {tones.map((tone, index) => (
                <div
                  key={index}
                  style={{
                    padding: '8px',
                    cursor: 'pointer',
                  }}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    handleToneSelect(tone); // Call the function to change tone
                  }}
                >
                  {tone} {/* Removed loading indicator here */}
                </div>
              ))}
            </div>
          )}

          {regeneratedText && (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div
                style={{
                  marginBottom: '5px',
                  padding: '10px',
                  backgroundColor: '#fff',
                  borderRadius: '5px',
                  boxShadow: '0px 0px 5px rgba(0,0,0,0.1)',
                  width: '600px',
                }}
              >
                <ReactMarkdown>{regeneratedText}</ReactMarkdown>
              </div>
              <div style={{ display: 'flex', gap: '10px' }}>
                <button
                  style={{
                    backgroundColor: 'green',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    padding: '5px 10px',
                  }}
                  onClick={handleReplaceClick}
                >
                  Accept
                </button>
                <button
                  style={{
                    backgroundColor: 'red',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    padding: '5px 10px',
                  }}
                  onClick={handleCancelClick}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Editor;