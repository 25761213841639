
import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Checkbox, FormControlLabel, Button, Typography, Container, Box, Snackbar, Alert, CircularProgress } from '@mui/material';
import { useForm } from 'react-hook-form';

const BetaProgramForm = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  // const [openSnackbar, setOpenSnackbar] = useState(false);  
  // const [snackbarMessage, setSnackbarMessage] = useState('');  
  // const [snackbarSeverity, setSnackbarSeverity] = useState('success'); 
  const [loading, setLoading] = useState(false);  // Manage loading state
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');
  const [agree, setAgree] = useState(false);

  // const handleCloseSnackbar = () => {
  //   setOpenSnackbar(false);
  // };

  const onSubmit = async (data) => {
    // console.log(data);
    setLoading(true); // Start loading when form is submitted
    try {
      const submissionData = {
        googleEmail: data.email,
        name: data.name,
        niche: data.niche,
        linkedinProfile: data.linkedin,
        privacyPolicy: data.agree,
        motivation: data.motivation,
      };

      setMessage('');
      setMessageColor('');

      // Send data to backend
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/askforaccess`, submissionData);

      // If successful, show success notification
      // console.log('1',response)
      if (response.status === 201) {
        // setSnackbarMessage(response.data.message);
        setMessage('Thanks! Your application to become a Beta User has been submitted. Please check your email for further action.');
        setMessageColor('green');
        // setSnackbarSeverity('success');
        // setOpenSnackbar(true);
      }
    } catch (error) {
      // console.error('Error submitting access request:', error);

      // Show error notification
      setMessageColor('red');
      if (error.response && error.response.data && error.response.data.message) {
        // setSnackbarMessage(error.response.data.message);
        // console.log('1',error.response.data.message)
        setMessage(error.response.data.message);
      } else {
        // setSnackbarMessage('An unexpected error occurred. Please try again.');
        // console.log('2')
        setMessage('An unexpected error occurred. Please try again.');
      }
      // setSnackbarSeverity('error');
      // setOpenSnackbar(true);
    } finally {
      setLoading(false); // Stop loading after request completes
    }
  };

  return (
    <Container component="main" maxWidth="xs" style={{ paddingLeft: '0px', paddingRight: '0px', }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          mt: 0,
          mx: 0,
          p: 3,
          borderRadius: '10px',
          // boxShadow: '0 3px 10px rgba(0, 0, 0, 0.1)',
          background: '#F3F6F8',
        }}
      >

        <Typography variant="h5" gutterBottom style={{ color: '#616263' }}>
          Join the Beta Program
        </Typography>


        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          <TextField
            {...register('email', { required: 'Email is required' })}
            label="Google Email Address"
            variant="outlined"
            fullWidth
            margin="normal"
            error={!!errors.email}
            helperText={errors.email ? errors.email.message : ''}
          />
          <TextField
            {...register('name', { required: 'Name is required' })}
            label="Name"
            variant="outlined"
            fullWidth
            margin="normal"
            error={!!errors.name}
            helperText={errors.name ? errors.name.message : ''}
          />
          <TextField
            {...register('niche', { required: 'Niche is required' })}
            label="Niche"
            variant="outlined"
            fullWidth
            margin="normal"
            error={!!errors.niche}
            helperText={errors.niche ? errors.niche.message : ''}
          />
          <TextField
            {...register('linkedin', { required: 'LinkedIn Profile Link is required' })}
            label="LinkedIn Profile Link"
            variant="outlined"
            fullWidth
            margin="normal"
            error={!!errors.linkedin}
            helperText={errors.linkedin ? errors.linkedin.message : ''}
          />
          {
            message ?
              (
                <Typography style={{ color: messageColor }}>{message}</Typography>
              ) : (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox {...register('agree', { required: true })}
                        color="primary"
                      />
                    }
                    label={
                      <span style={{ color: '#616263', textAlign: 'left', fontSize: '15px' }}>
                        I agree to be contacted by Diittoo team to collect feedback by email or personal messages.
                      </span>
                    }
                    sx={{
                      alignItems: 'flex-start', 
                      '.MuiFormControlLabel-label': {
                        textAlign: 'left', 
                      },
                    }}
                  />

                  {errors.agree && (
                    <Typography color="error" variant="body2" >
                      You must agree to continue.
                    </Typography>
                  )}
                </>
              )
          }
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            disabled={loading}  // Disable button while loading
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default BetaProgramForm;