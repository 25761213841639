// hooks/useAuth.js
import { useState, useEffect } from 'react';

export const useAuth = () => {
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const checkTokenValidity = async (token) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/user`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (response.ok) {
        return true; // Token is valid
      } else {
        return false; // Token is not valid
      }
    } catch (error) {
      console.error('Error verifying token:', error);
      return false;
    }
  };

  const verifyAuthentication = async () => {
    const token = localStorage.getItem('authToken');
    if (token) {
      const valid = await checkTokenValidity(token);
      setIsAuthenticated(valid);
    } else {
      setIsAuthenticated(false);
    }
    setIsAuthChecked(true);
  };

  useEffect(() => {
    verifyAuthentication();
  }, []);

  return { isAuthChecked, isAuthenticated };
};
