





// import React, { useState } from 'react';
// import { Modal, Spinner } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { FaLinkedin } from 'react-icons/fa';
// import './LinkedInModal.css';

// const LinkedInModal = ({ isOpen, onClose, editorContent }) => {
//     const token = localStorage.getItem('authToken');
//     const [isLoading, setIsLoading] = useState(false);
//     const [apiResponse, setApiResponse] = useState(null);

//     const postDocument = async (editorContent) => {
//         setIsLoading(true);
//         setApiResponse(null);

//         try {
//             const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/postToLinkedIn`, {
//                 method: 'POST',
//                 headers: {
//                     'Authorization': `Bearer ${token}`,
//                     'Content-Type': 'application/json',
//                 },
//                 credentials: 'include',
//                 body: JSON.stringify({ content: editorContent }),
//             });

//             if (!response.ok) {
//                 const errorMessage = await response.text();
//                 throw new Error(`Error: ${response.status} - ${errorMessage}`);
//             }

//             const result = await response.json();
//             setApiResponse(result);
//         } catch (error) {
//             console.error(error);
//             setApiResponse({ error: true, details: error.message });
//         } finally {
//             setIsLoading(false);
//         }
//     };

//     const handleModalClose = () => {
//         setApiResponse(null);  // Clear the response when modal is closed
//         onClose();
//     };

//     return (
//         <Modal
//             show={isOpen}
//             onHide={handleModalClose}  // Use the modified close handler
//             centered
//             dialogClassName="modal-custom"
//             aria-labelledby="linkedin-modal-title"
//             aria-modal="true"
//         >
//             <Modal.Header closeButton className="bg-light">
//                 <Modal.Title id="linkedin-modal-title" className="text-center text-white font-weight-bold">
//                     <FaLinkedin className="mr-2" /> Confirm LinkedIn Post
//                 </Modal.Title>
//             </Modal.Header>
//             <Modal.Body className="text-center text-muted">
//                 {isLoading ? (
//                     <>
//                         <Spinner animation="border" variant="primary" />
//                         <p className="lead mt-2">Posting your document to LinkedIn...</p>
//                     </>
//                 ) : (
//                     <>
//                         {!apiResponse && (
//                             <>
//                                 <p className="lead">Do you really want to post on LinkedIn?</p>
//                                 <p>Double-check your content before sharing it with your<br/> network.</p>
//                                 <p>Character count: {editorContent.length}</p>
//                             </>
//                         )}
//                         {apiResponse && apiResponse.error ? (
//                             <div className="modal-response mt-3">
//                                 <h6>Error:</h6>
//                                 <pre>
//                                     <code>{JSON.stringify(apiResponse.details, null, 2)}</code>
//                                 </pre>
//                             </div>
//                         ) : (
//                             apiResponse && (
//                                 <div className="modal-response mt-3">
//                                     <h6>Success:</h6>
//                                     <p style={{ color: 'green' }}>
//                                         Your post has been published! You can view it &nbsp;
//                                         <a href={`https://www.linkedin.com/feed/update/${apiResponse.postId}`} target="_blank" rel="noopener noreferrer" style={{ color: 'green', textDecoration: 'underline' }}>
//                                             here
//                                         </a>.
//                                     </p>
//                                 </div>
//                             )
//                         )}
//                     </>
//                 )}
//             </Modal.Body>
//             {!apiResponse && (
//                 <Modal.Footer className="justify-content-center">
//                     <button
//                         onClick={handleModalClose}
//                         style={{
//                             width: '100px',
//                             margin: '0 10px',
//                             backgroundColor: 'transparent',
//                             color: '#FF4D4D',
//                             border: '2px solid #FF4D4D',
//                             borderRadius: '5px',
//                             padding: '10px',
//                             fontSize: '16px',
//                             cursor: 'pointer',
//                             transition: 'all 0.3s ease',
//                             boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
//                         }}
//                         onMouseEnter={e => e.currentTarget.style.backgroundColor = '#FFCCCC'}
//                         onMouseLeave={e => e.currentTarget.style.backgroundColor = 'transparent'}
//                     >
//                         No
//                     </button>
//                     <button
//                         onClick={() => postDocument(editorContent)}
//                         disabled={isLoading}
//                         style={{
//                             width: '100px',
//                             margin: '0 10px',
//                             backgroundColor: '#0072FF',
//                             color: '#fff',
//                             border: 'none',
//                             borderRadius: '5px',
//                             padding: '10px',
//                             fontSize: '16px',
//                             cursor: 'pointer',
//                             transition: 'all 0.3s ease',
//                             boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
//                         }}
//                         onMouseEnter={e => e.currentTarget.style.backgroundColor = '#005BB5'}
//                         onMouseLeave={e => e.currentTarget.style.backgroundColor = '#0072FF'}
//                     >
//                         Yes
//                     </button>
//                 </Modal.Footer>
//             )}
//         </Modal>
//     );
// };

// export default LinkedInModal;




import React, { useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaLinkedin } from 'react-icons/fa';
import './LinkedInModal.css';

const LinkedInModal = ({ isOpen, onClose, editorContent }) => {
    const token = localStorage.getItem('authToken');
    const [isLoading, setIsLoading] = useState(false);
    const [apiResponse, setApiResponse] = useState(null);

    // Function to parse the HTML and count the text characters
    const getTextCharacterCount = (htmlContent) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlContent;
        const textContent = tempDiv.innerText || tempDiv.textContent;  // Extract text from HTML
        return textContent.length;
    };

    const postDocument = async (editorContent) => {
        setIsLoading(true);
        setApiResponse(null);

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/postToLinkedIn`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ content: editorContent }),
            });

            if (!response.ok) {
                const errorMessage = await response.text();
                throw new Error(`Error: ${response.status} - ${errorMessage}`);
            }

            const result = await response.json();
            setApiResponse(result);
        } catch (error) {
            console.error(error);
            setApiResponse({ error: true, details: error.message });
        } finally {
            setIsLoading(false);
        }
    };

    const handleModalClose = () => {
        setApiResponse(null);  // Clear the response when modal is closed
        onClose();
    };

    return (
        <Modal
            show={isOpen}
            onHide={handleModalClose}  // Use the modified close handler
            centered
            dialogClassName="modal-custom"
            aria-labelledby="linkedin-modal-title"
            aria-modal="true"
        >
            <Modal.Header closeButton className="bg-light">
                <Modal.Title id="linkedin-modal-title" className="text-center text-white font-weight-bold">
                    <FaLinkedin className="mr-2" /> Confirm LinkedIn Post
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center text-muted">
                {isLoading ? (
                    <>
                        <Spinner animation="border" variant="primary" />
                        <p className="lead mt-2">Posting your document to LinkedIn...</p>
                    </>
                ) : (
                    <>
                        {!apiResponse && (
                            <>
                                <p className="lead">Do you really want to post on LinkedIn?</p>
                                <p>Double-check your content before sharing it with your <br/> network.</p>
                                {/* Display character count of parsed HTML text */}
                                <p>Character count(Max 3000 allowed): {getTextCharacterCount(editorContent)}</p>
                            </>
                        )}
                        {apiResponse && apiResponse.error ? (
                            <div className="modal-response mt-3">
                                <h6>Error:</h6>
                                <pre>
                                    <code>{JSON.stringify(apiResponse.details, null, 2)}</code>
                                </pre>
                            </div>
                        ) : (
                            apiResponse && (
                                <div className="modal-response mt-3">
                                    <h6>Success:</h6>
                                    <p style={{ color: 'green' }}>
                                        Your post has been published! You can view it &nbsp;
                                        <a href={`https://www.linkedin.com/feed/update/${apiResponse.postId}`} target="_blank" rel="noopener noreferrer" style={{ color: 'green', textDecoration: 'underline' }}>
                                            here
                                        </a>.
                                    </p>
                                </div>
                            )
                        )}
                    </>
                )}
            </Modal.Body>
            {!apiResponse && (
                <Modal.Footer className="justify-content-center">
                    <button
                        onClick={handleModalClose}
                        style={{
                            width: '100px',
                            margin: '0 10px',
                            backgroundColor: 'transparent',
                            color: '#FF4D4D',
                            border: '2px solid #FF4D4D',
                            borderRadius: '5px',
                            padding: '10px',
                            fontSize: '16px',
                            cursor: 'pointer',
                            transition: 'all 0.3s ease',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        }}
                        onMouseEnter={e => e.currentTarget.style.backgroundColor = '#FFCCCC'}
                        onMouseLeave={e => e.currentTarget.style.backgroundColor = 'transparent'}
                    >
                        No
                    </button>
                    <button
                        onClick={() => postDocument(editorContent)}
                        disabled={isLoading}
                        style={{
                            width: '100px',
                            margin: '0 10px',
                            backgroundColor: '#0072FF',
                            color: '#fff',
                            border: 'none',
                            borderRadius: '5px',
                            padding: '10px',
                            fontSize: '16px',
                            cursor: 'pointer',
                            transition: 'all 0.3s ease',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
                        }}
                        onMouseEnter={e => e.currentTarget.style.backgroundColor = '#005BB5'}
                        onMouseLeave={e => e.currentTarget.style.backgroundColor = '#0072FF'}
                    >
                        Yes
                    </button>
                </Modal.Footer>
            )}
        </Modal>
    );
};

export default LinkedInModal;
