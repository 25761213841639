
import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaSpinner } from 'react-icons/fa'; 
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import './ModalDoc.css';

// Create a custom Alert component with your desired color
const CustomAlert = styled(MuiAlert)(({ theme }) => ({
    backgroundColor: '#0072FF', // Set your custom background color
    color: 'white', // Ensure text color is white for contrast
}));

const ModalDoc = ({ modalSave, setModalSave,  promptId}) => {
    const [documentName, setDocumentName] = useState('');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false); 
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const navigate = useNavigate();
    const token = localStorage.getItem('authToken');

    const handleOk = async () => {
        const defaultDocumentName = documentName.trim() === '' ? '' : documentName;
        if (defaultDocumentName === '') {
            alert("Document name can't be empty");
            return;
        }

        setLoading(true); 

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/save-document`, {
                documentName: defaultDocumentName,
                content: '',
                description: description,
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
            });

            if (response.status === 200) {
                setLoading(false); 
                setModalSave(false);
                setSnackbarMessage('Document created successfully!');
                setOpenSnackbar(true);
                localStorage.setItem("docId", response.data.Id);
                const url = promptId ? `/writer/${promptId}` : '/writer';
                navigate(url, {
                    state: {
                        doc: {
                            title: defaultDocumentName,
                            content: '',
                            description: description,
                            ID: response.data.Id,
                        }
                    }
                });
            } else {
                setSnackbarMessage('Failed to create document!');
                setOpenSnackbar(true);
            }
        } catch (error) {
            setSnackbarMessage('Failed to create document!');
            setOpenSnackbar(true);
        } finally {
            setLoading(false); 
        }
    };

    const handleCancel = () => {
        setModalSave(false);
        setDocumentName('');
        setDescription('');
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <>
            <Modal show={modalSave} onHide={handleCancel} style={{ marginTop: '70px' }}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="documentName">
                        <Form.Label>Document Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={documentName}
                            onChange={(e) => setDocumentName(e.target.value)}
                            placeholder="Enter document name"
                        />
                    </Form.Group>
                    <Form.Group controlId="description">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            type="text"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Enter description"
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        variant="secondary"
                        className="cancel-button"
                        onClick={handleCancel}
                        disabled={loading} 
                    >
                        Cancel
                    </button>
                    <button
                        variant="primary"
                        className="save-button"
                        onClick={handleOk}
                        disabled={loading} 
                    >
                        {loading ? <FaSpinner className="loading-icon" /> : 'Ok'}
                    </button>
                </Modal.Footer>
            </Modal>

            <Snackbar open={openSnackbar} autoHideDuration={1000} onClose={handleCloseSnackbar}>
                <CustomAlert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </CustomAlert>
            </Snackbar>
        </>
    );
};

export default ModalDoc;
