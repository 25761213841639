import React from 'react';
import './HelpSupport.css'; // External CSS file
// import CustomNavbar from './CustomNavbar';

const HelpSupport = () => {
    return (
        <>
            {/* <CustomNavbar /> */}
            <div className="help-support-container">
                <h2 className="help-support-heading">Diittoo Help & Support</h2>
                <p className="help-support-text">
                    You can reach us at <a href="mailto:support@diittoo.com">support@diittoo.com</a> or via
                    <a href="tel:+911234567890"> +91 12345 67890</a>.
                </p>
                <p className="help-support-response">
                    We'll get back to you as soon as we can, typically within a few hours.
                </p>
                <p className="help-support-info">
                    Here you can find solutions to the most common issues. You can also contact us to report any issues,
                    get help, or suggest new features.
                </p>
            </div>
        </>
    );
};

export default HelpSupport;
