import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';

Modal.setAppElement('#root'); // Needed for accessibility reasons

const ScheduleModal = ({ isOpen, onClose }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [scheduledDate, setScheduledDate] = useState(null);
  const [isPosted, setIsPosted] = useState(false);

  const docId = localStorage.getItem('docId');
  const authToken = localStorage.getItem('authToken');

  const checkScheduledStatus = async () => {
    try {
      setLoading(true); // Start loading
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/auth/checkScheduledDateAndStatus`,
        { docId: docId },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      console.log("Response data:", response.data);
      const { scheduledTime, isPosted } = response.data;
      setIsPosted(isPosted);

      if (scheduledTime.ist) {
        const scheduledDate = new Date(scheduledTime.ist);
        setScheduledDate(scheduledDate);
      } else {
        setScheduledDate(null); // Reset scheduled date if none exists
      }
    } catch (err) {
      console.error('Error checking scheduled status:', err);
      setError('Failed to retrieve scheduled status.');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    if (isOpen) {
      checkScheduledStatus();
    }
  }, [isOpen]);

  const convertToIST = (date) => {
    const offset = 5.5 * 60 * 60 * 1000;
    const utcDate = new Date(date.getTime() + offset);
    return utcDate.toISOString().replace('Z', '+05:30');
  };

  const handleSavePost = async () => {
    setLoading(true);
    setError('');
    setSuccess(false);
    try {
      const istDate = convertToIST(selectedDate);
      const postData = {
        docId,
        scheduledDate: istDate,
      };

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/updateScheduledPost`, postData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      setSuccess(true);
    } catch (error) {
      console.error('Error saving post:', error);
      setError('Failed to save the post. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setSuccess(false);
    setError('');
    setScheduledDate(null); // Reset scheduled date
    onClose();
  };

  const handleRescheduleConfirm = () => {
    handleSavePost(); // Call save post on confirmation
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width: '400px',
          borderRadius: '12px',
          padding: '20px',
          boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
          position: 'relative',
        },
      }}
    >
      {/* CSS for Spinner */}
      <style>
        {`
          .spinner {
            border: 4px solid rgba(0, 0, 0, 0.1);
            border-left-color: #0072ff; /* Customize to match your design */
            border-radius: 50%;
            width: 40px;
            height: 40px;
            animation: spin 1s linear infinite;
            margin: auto;
          }

          @keyframes spin {
            to {
              transform: rotate(360deg);
            }
          }
        `}
      </style>

      {/* Cross Button */}
      <button
        onClick={handleCloseModal}
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          background: 'transparent',
          border: 'none',
          fontSize: '18px',
          cursor: 'pointer',
        }}
      >
        &times;
      </button>

      <h2>{scheduledDate ? 'Confirm Rescheduling' : 'Schedule Your Post'}</h2>

      {loading ? (
        <div className="spinner"></div> // Loading Spinner
      ) : success ? (
        <div style={{ textAlign: 'center', color: '#28a745' }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            className="bi bi-check-circle"
            viewBox="0 0 16 16"
            style={{ marginBottom: '10px' }}
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM3.97 7.03a.75.75 0 0 0-1.06 1.06l3 3a.75.75 0 0 0 1.08-.02l7-8a.75.75 0 0 0-1.08-1.04l-6.47 7.33-2.47-2.33z" />
          </svg>
          <p>Your post has been scheduled at {selectedDate.toLocaleString()}</p>
        </div>
      ) : (
        <>
          {scheduledDate ? (
            <p style={{ color: 'red' }}>
              This post is scheduled to be posted on {scheduledDate.toLocaleString()}. Do you want to reschedule it?
            </p>
          ) : (
            <p>There is currently no scheduled time. Please select a date and time to schedule your post.</p>
          )}
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            showTimeSelect
            timeIntervals={30}
            dateFormat="MMMM d, yyyy h:mm aa"
            inline
            disabled={isPosted}
          />
          {error && <div style={{ color: 'red' }}>{error}</div>}
          <button
            onClick={scheduledDate ? handleRescheduleConfirm : handleSavePost}
            style={{
              marginTop: '20px',
              padding: '10px 20px',
              backgroundColor: '#0072FF',
              color: '#fff',
              border: 'none',
              borderRadius: '8px',
              cursor: 'pointer',
            }}
            disabled={isPosted}
          >
            {scheduledDate ? 'Yes, Reschedule' : 'Schedule Post'}
          </button>
          <button
            onClick={handleCloseModal}
            style={{
              marginTop: '10px',
              padding: '10px 20px',
              backgroundColor: '#ccc',
              color: '#000',
              border: 'none',
              borderRadius: '8px',
              cursor: 'pointer',
            }}
          >
            No, Cancel
          </button>
        </>
      )}
    </Modal>
  );
};

export default ScheduleModal;
