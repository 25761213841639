import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const LinkedInLogin = () => {
    const clientId = '86bfy4k2z9y56h'; // Your LinkedIn Client ID
    const redirectUri = `${process.env.REACT_APP_BACKEND_URL}/auth/linkedin/callback`; // Your callback URL
    const navigate = useNavigate();
    const [linkedInStatus, setLinkedInStatus] = useState(null);
    const [openDisconnectModal, setOpenDisconnectModal] = useState(false); // For controlling the disconnect modal
    const [isLinkedInConnected, setIsLinkedInConnected] = useState(false); // New state for LinkedIn connection

    const handleLogin = () => {
        const token = localStorage.getItem('authToken');
        const state = `${Math.random().toString(36).substring(2, 15)}:${token}`;

        const authUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&state=${state}&scope=openid profile email w_member_social`;

        window.location.href = authUrl;
    };

    const handleConnect = () => {
        if (linkedInStatus?.includes('associated')) {
            // Directly proceed to login if a LinkedIn account is already associated
            handleLogin();
        } else {
            // Proceed to login if no account is associated
            handleLogin();
        }
    };

    const handleDisconnect = () => {
        // Show disconnect confirmation modal
        setOpenDisconnectModal(true);
    };

    const handleDisconnectConfirm = async () => {
        const token = localStorage.getItem('authToken');
        if (!token) {
            setLinkedInStatus('No auth token found');
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/disconnectLinkedAccount`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                setLinkedInStatus('Successfully disconnected LinkedIn account.');
                setIsLinkedInConnected(false); // Update the connection state
            } else {
                setLinkedInStatus('Error disconnecting LinkedIn account.');
            }
        } catch (error) {
            setLinkedInStatus('Server error while disconnecting.');
        } finally {
            setOpenDisconnectModal(false); // Close the disconnect modal
        }
    };

    useEffect(() => {
        const checkLinkedInAccount = async () => {
            try {
                const token = localStorage.getItem('authToken');
                if (!token) {
                    setLinkedInStatus('No auth token found');
                    return;
                }

                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/getLinkedInProfile`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setLinkedInStatus(`Account associated with LinkedIn account with email: ${data.email}`);
                    setIsLinkedInConnected(true); // Update connection state
                } else if (response.status === 404) {
                    setLinkedInStatus('No LinkedIn account associated with this account');
                    setIsLinkedInConnected(false); // Update connection state
                } else {
                    setLinkedInStatus('Error checking LinkedIn account');
                }
            } catch (error) {
                setLinkedInStatus('Server error');
            }
        };

        checkLinkedInAccount();
    }, []);

    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <Button
                variant="contained"
                color="primary"
                startIcon={<LinkedInIcon />}
                onClick={handleConnect}
                disabled={isLinkedInConnected} // Disable button if connected
                style={{
                    backgroundColor: isLinkedInConnected ? '#ccc' : '#0077B5', // Change background if disabled
                    color: '#ffffff',
                    borderRadius: '8px',
                    padding: '10px 20px',
                    textTransform: 'none',
                    fontWeight: 'bold',
                }}
            >
                Connect to LinkedIn
            </Button>
            <br />
            {isLinkedInConnected && (
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleDisconnect}
                    style={{
                        backgroundColor: '#f44336',
                        color: '#ffffff',
                        borderRadius: '8px',
                        padding: '10px 20px',
                        textTransform: 'none',
                        fontWeight: 'bold',
                        marginTop: '10px', // Add margin to create gap
                    }}
                >
                    Disconnect LinkedIn
                </Button>
            )}

            {linkedInStatus && (
                <div style={{ marginTop: '20px', color: linkedInStatus.includes('associated') ? 'green' : 'red' }}>
                    {linkedInStatus}
                </div>
            )}

            {/* Disconnect Modal confirmation */}
            <Dialog open={openDisconnectModal} onClose={() => setOpenDisconnectModal(false)} PaperProps={{ style: { borderRadius: 15, padding: '20px' } }}>
                <DialogTitle style={{ textAlign: 'center', fontSize: '22px', fontWeight: 600 }}>
                    Disconnect LinkedIn Account
                </DialogTitle>
                <DialogContent>
                    <Typography style={{ fontSize: '16px', color: '#555', textAlign: 'center', marginBottom: '20px' }}>
                        Are you sure that you want to disconnect your LinkedIn account?
                    </Typography>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button onClick={() => setOpenDisconnectModal(false)} style={{ color: '#f44336', fontWeight: 'bold' }}>
                        Cancel
                    </Button>
                    <Button onClick={handleDisconnectConfirm} variant="contained" style={{ backgroundColor: '#0077B5', color: '#ffffff', fontWeight: 'bold' }}>
                        Yes, Disconnect
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default LinkedInLogin;
