import React, { useState } from 'react';
import './LandingPage.css';
import NavbarBeta from './NavbarBeta';
import BetaProgramForm from './BetaProgramForm';
import BetaFormHr from './BetaFormHr';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import logo from './images/logo.png';
import { FaLinkedin, FaFacebook, FaTwitter} from "react-icons/fa";

const LandingPage = () => {
  const [isTrialStarted, setIsTrialStarted] = useState(false);
  const [isBetaActive, setBetaActive] = useState(false);
  const navigate = useNavigate();

  const handleStartTrial = () => {
    setIsTrialStarted(!isTrialStarted);
  };
  const handleBetaClick = () => {
    setBetaActive(!isBetaActive);
  };
  const scrollToSection = () => {
    if (window.matchMedia('(max-width: 768px)').matches) {
      const section = document.getElementById('target-section');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <>
      < NavbarBeta onBetaAccessClick={handleStartTrial} />

      {/* Hero Section */}
      <section className="hero" >
        <div className="hero-content" >
          <div className="hero-text">
            <h1>Boost Your LinkedIn Presence with AI-Driven Content</h1>
            <p>Create engaging posts, articles, and updates effortlessly using our intelligent assistant.</p>
            <Button
              variant="contained"
              color="primary"
              className='cta-button'
            >
              Beta Access
            </Button>
          </div>
          <div className="hero-image" id='target-section' >
            {/* BetaProgramForm with conditional class based on state */}
            <div className={` beta-xs ${isTrialStarted ? 'beta' : ''}`} >
              <BetaProgramForm />
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="features" id="features" >
        <div className="container features-sm">
          <h2>Features</h2>
          <div className="features-grid">
            <div className="feature-item">
              <i className="fas fa-robot"></i>
              <h3>AI Content Generation</h3>
              <p>Generate professional LinkedIn posts in minutes with our advanced AI algorithms.</p>
            </div>
            <div className="feature-item">
              <i className="fas fa-template"></i>
              <i className="fas fa-users-cog"></i>
              <h3>Content Tailored for You</h3>
              <p>Generate content specifically with your own inputs. Edit as much as you wish!</p>
            </div>
            <div className="feature-item">
              <i className="fas fa-calendar-alt"></i>
              <h3>Optimize Engagement</h3>
              <p>Craft posts that maximize engagement and grow your professional network.</p>
            </div>
            {/* New Feature: Post to LinkedIn */}
            <div className="feature-item">
              <i className="fas fa-share-square"></i>
              <h3>Post to LinkedIn</h3>
              <p>Seamlessly post your AI-generated content directly to LinkedIn with just one click.</p>
            </div>
            {/* New Feature: Scheduling Post in Bulk */}
            <div className="feature-item">
              <i className="fas fa-tasks"></i>
              <h3>Schedule Posts in Bulk</h3>
              <p>Schedule and automate multiple posts in advance to maintain a consistent posting schedule.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="benefits" >
        <div className="container">
          <h2>Benefits</h2>
          <div className="benefits-grid">
            <div className="benefit-item">
              <i className="fas fa-clock"></i>
              <h3>Save Time</h3>
              <p>Automate your content creation process and focus on what matters most.</p>
            </div>
            <div className="benefit-item">
              <i className="fas fa-chart-bar"></i>
              <h3>Increase Engagement</h3>
              <p>Craft compelling posts that resonate with your LinkedIn audience.</p>
            </div>
            <div className="benefit-item">
              <i className="fas fa-user-circle"></i>
              <h3>Enhance Your Personal Brand</h3>
              <p>Maintain a consistent and professional presence on LinkedIn.</p>
            </div>
            <div className="benefit-item">
              <i className="fas fa-sync-alt"></i>
              <h3>Stay Consistent</h3>
              <p>Regularly publish content without the hassle of brainstorming ideas.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Horizontal beta form*/}
      <section className='footer-beta' >
        <div className='container'  >
        <Button
          variant="contained"
          color="primary"
          // fullWidth
          sx={{  width:'90%',marginLeft:'5%' }}
          className='beta-btn-footer'
          onClick={() => { handleBetaClick(); scrollToSection(); }}
        >
          Beta Access
        </Button>
        < BetaFormHr isActive={isBetaActive} />
        </div>
      </section>

      <footer 
      // style={{border:'2px solid green'}}
      >
        <div className="container footer-container"
        //  style={{border:'2px solid white'}}
         >

          {/* Left Section */}

          <div className="footer-left">
            {/* <img src={logo} alt="Logo" className="footer-logo" /> */}
            <span className='logo-text-footer'> DIITTOO </span>

            <p className='about'>About Us</p>

            <div className="footer-social">
              <a href="https://linkedin.com" className="social-link"><FaLinkedin className='logo-icon' /></a>
              <a href="https://facebook.com" className="social-link"><FaFacebook className='logo-icon' /></a>
              <a href="https://twitter.com" className="social-link"><FaTwitter className='logo-icon' /></a>
            </div>
          </div>

          <div className='vertical-line'></div>

          {/* Middle Section */}

          <div className="footer-middle">
            <nav>
              <ul>
                <li onClick={() => navigate('/privacy')}>Privacy Policy</li>
                <li onClick={() => navigate('/dpa')}>Data Processing</li>
                <li onClick={() => navigate('/terms-of-service')}>Terms of Service</li>
                <li onClick={() => navigate('/help&support')}>Help & Support</li>
              </ul>
            </nav>
          </div>

          <div className='vertical-line'></div>

          {/* Right Section */}

          <div className="footer-right">
            <p>Subscribe to Updates</p>
            <form className="subscribe-form" onSubmit={(e) => { e.preventDefault(); }}>
              <input type="email" placeholder="Enter your email" />
              <Button type="">Submit</Button>
            </form>
          </div>
        </div>
      </footer>



    </>
  );
};

export default LandingPage;