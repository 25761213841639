











// import React, { useEffect } from 'react';
// import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
// import HomePage from './components/Homepage';

// import CombinedPanel from './components/CombinedPanel';
// import LoginPage from './components/LoginPage';
// import BetaProgramForm from './components/BetaProgramForm';
// import Privacy from './components/Privacy';
// import DataProcessingAgreement from './components/DataProcessingAgreement';
// import TermsOfService from './components/TermsOfService';
// import HelpSupport from './components/HelpSupport';
// import './App.css';
// import LandingPage from './components/LandingPage';
// import Navbar from './components/Navbar';
// import { useAuth } from './components/hooks/useAuth';
// const App = () => {
//   return (
//     <Router>
//       <AppRoutes />
//     </Router>
//   );
// };

// const AppRoutes = () => {
//   const location = useLocation();
//   const { isAuthChecked,isAuthenticated } = useAuth();

//   // Check if the user is authenticated
//   // const isAuthenticated = () => {
//   //   const token = localStorage.getItem('authToken');
//   //   return token !== null;
//   // };

//   // Redirect to homepage if authenticated
//   // useEffect(() => {
//   //   if (isAuthenticated() && location.pathname === '/') {
//   //     window.location.href = '/dashboard';
//   //   }
//   // }, [location,isAuthenticated]);
//   // useEffect(() => {
//   //   if (isAuthChecked && isAuthenticated && location.pathname === '/') {
//   //     window.location.href = '/dashboard';
//   //   }
//   // }, [location, isAuthenticated, isAuthChecked]);
  
  
//   const shouldShowNavbar = location.pathname !== '/landing'&& location.pathname !== '/';

//   return (
//     <>
//     {shouldShowNavbar && <Navbar />}
//       <Routes>
//         <Route path="/landing" element={<LandingPage />} />
//         <Route path="/" element={<LoginPage />} />
//         <Route path="/betaApplication" element={<BetaProgramForm />} />
//         <Route path="/dashboard" element={isAuthenticated? <HomePage /> : <Navigate to="/" />} />
//         <Route path="/writer" element={isAuthenticated ? <CombinedPanel /> : <Navigate to="/" />} />
//         <Route path="/writer/:promptId" element={isAuthenticated? <CombinedPanel /> : <Navigate to="/" />} />
//         <Route path="/privacy" element={<Privacy />} />
//         <Route path="/dpa" element={<DataProcessingAgreement />} />
//         <Route path="/terms-of-service" element={<TermsOfService />} />
//         <Route path="/help&support" element={<HelpSupport />} />
//       </Routes>
//     </>
//   );
// };

// export default App







// import React from 'react';
// import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
// import HomePage from './components/Homepage';
// import CombinedPanel from './components/CombinedPanel';
// import LoginPage from './components/LoginPage';
// import BetaProgramForm from './components/BetaProgramForm';
// import Privacy from './components/Privacy';
// import DataProcessingAgreement from './components/DataProcessingAgreement';
// import TermsOfService from './components/TermsOfService';
// import HelpSupport from './components/HelpSupport';
// import LandingPage from './components/LandingPage';
// import Navbar from './components/Navbar';
// import { useAuth } from './components/hooks/useAuth';
// import { ThreeDot } from 'react-loading-indicators'; // Import the loading indicator

// const Loading = () => (
//   <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
//     <ThreeDot color="#32cd32" size="large" text="Authenticating....." />
//   </div>
// );

// const AppRoutes = () => {
//   const location = useLocation();
//   const { isAuthChecked, isAuthenticated } = useAuth();

//   // Show loader while authentication status is being checked
//   if (!isAuthChecked) {
//     return <Loading />;
//   }

//   const shouldShowNavbar = location.pathname !== '/landing' && location.pathname !== '/';

//   return (
//     <>
//       {shouldShowNavbar && <Navbar />}
//       <Routes>
//         <Route path="/landing" element={<LandingPage />} />
//         <Route path="/" element={<LoginPage />} />
//         <Route path="/betaApplication" element={<BetaProgramForm />} />
//         <Route path="/dashboard" element={isAuthenticated ? <HomePage /> : <Navigate to="/" />} />
//         <Route path="/writer" element={isAuthenticated ? <CombinedPanel /> : <Navigate to="/" />} />
//         <Route path="/writer/:promptId" element={isAuthenticated ? <CombinedPanel /> : <Navigate to="/" />} />
//         <Route path="/privacy" element={<Privacy />} />
//         <Route path="/dpa" element={<DataProcessingAgreement />} />
//         <Route path="/terms-of-service" element={<TermsOfService />} />
//         <Route path="/help&support" element={<HelpSupport />} />
//       </Routes>
//     </>
//   );
// };

// const App = () => {
//   return (
//     <Router>
//       <AppRoutes />
//     </Router>
//   );
// };

// export default App;


import 'react-toastify/dist/ReactToastify.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import HomePage from './components/Homepage';
import CombinedPanel from './components/CombinedPanel';
import LoginPage from './components/LoginPage';
import BetaProgramForm from './components/BetaProgramForm';
import Privacy from './components/Privacy';
import DataProcessingAgreement from './components/DataProcessingAgreement';
import TermsOfService from './components/TermsOfService';
import HelpSupport from './components/HelpSupport';
import LandingPage from './components/LandingPage';
import Navbar from './components/Navbar';
import { useAuth } from './components/hooks/useAuth';
import { ThreeDot } from 'react-loading-indicators'; // Import the loading indicator

// List of interesting facts
const facts = [
  "Did you know? AI can personalize marketing strategies based on consumer behavior.",
  "Did you know? Chatbots can engage customers 24/7, enhancing service.",
  "Did you know? AI predicts trends by analyzing vast data sets.",
  "Did you know? Automated tools can generate marketing content quickly.",
  "Did you know? Machine learning optimizes ad placements for better ROI."
];


// Function to get a random fact
const getRandomFact = () => {
  return facts[Math.floor(Math.random() * facts.length)];
};

// Enhanced Loading Component with random facts
const Loading = () => {
  const [fact, setFact] = useState("");

  useEffect(() => {
    setFact(getRandomFact());
    const interval = setInterval(() => {
      setFact(getRandomFact());
    }, 4000); // Change fact every 4 seconds
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
      <ThreeDot color="#32cd32" size="large" text="Authenticating....." />
      <div
        style={{
          marginTop: '20px',
          fontSize: '18px',
          fontStyle: 'italic',
          color: '#555',
          animation: 'fadeIn 1s ease-in-out'
        }}
      >
        {fact}
      </div>

      {/* CSS for fade-in animation */}
      <style>
        {`
          @keyframes fadeIn {
            0% { opacity: 0; }
            100% { opacity: 1; }
          }
        `}
      </style>
    </div>
  );
};

const AppRoutes = () => {
  const location = useLocation();
  const { isAuthChecked, isAuthenticated } = useAuth();

  // Show loader while authentication status is being checked
  if (!isAuthChecked) {
    return <Loading />;
  }

  const shouldShowNavbar = location.pathname !== '/landing' && location.pathname !== '/';

  return (
    <>
      {shouldShowNavbar && <Navbar />}
      <Routes>
        <Route path="/landing" element={<LandingPage />} />
        <Route path="/" element={<LoginPage />} />
        <Route path="/betaApplication" element={<BetaProgramForm />} />
        <Route path="/dashboard" element={isAuthenticated ? <HomePage /> : <Navigate to="/" />} />
        <Route path="/writer" element={isAuthenticated ? <CombinedPanel /> : <Navigate to="/" />} />
        <Route path="/writer/:promptId" element={isAuthenticated ? <CombinedPanel /> : <Navigate to="/" />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/dpa" element={<DataProcessingAgreement />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/help&support" element={<HelpSupport />} />
      </Routes>
    </>
  );
};

const App = () => {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
};

export default App;
