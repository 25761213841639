

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import img1 from './images/add-new-doc-logo.webp';
import img2 from './images/5.png';
import './HomePage.css'; 
import { MdDelete } from 'react-icons/md';
import ModalDoc from './ModalDoc.jsx';
import ModalDelete from './ModalDelete.jsx';
// import Navbar from './CustomNavbar.jsx';
import LinkedInLogin from './linkedin/linkedin.jsx';
const HomePage = () => {
    const token = localStorage.getItem('authToken');
    const [modalSave, setModalSave] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [docToDelete, setDocToDelete] = useState(null);
    const navigate = useNavigate();

    // State for tokens used
    const [tokens, setTokens] = useState({
        tokensUsed: 0,
        inputToken: 0,
        outputToken: 0,
        totalToken: 0,
    });

    const [loading, setLoading] = useState(true); // State for loading status
    const [error, setError] = useState(null); // State for error handling
    const [documents, setDocuments] = useState([]); // State for documents

    // Function to fetch tokens used from the API
    const fetchTokens = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/user/tokens`, {
                credentials: 'include',
                headers: {
                    'Authorization': `Bearer ${token}`, // Include the token in the headers
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch tokens');
            }

            const data = await response.json();
            setTokens({
                tokensUsed: data.tokensUsed,
                inputToken: data.inputToken,
                outputToken: data.outputToken,
                totalToken: data.totalToken,
            });
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    // Function to fetch documents for the user
    const fetchDocuments = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/user/documents`, {
                headers: {
                    'Authorization': `Bearer ${token}`, // Include the token in the headers
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error('Failed to fetch documents');
            }

            const data = await response.json();
            setDocuments(data.documents);
        } catch (error) {
            setError(error.message);
        }
    };

    // Fetch tokens and documents when the component mounts
    useEffect(() => {
        fetchTokens();
        fetchDocuments();
    }, []);

    // Function to dynamically load Meta Pixel script on page load
    useEffect(() => {
        const loadMetaPixel = () => {
            (function(f, b, e, v, n, t, s) {
                if (f.fbq) return;
                n = f.fbq = function() {
                    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
                };
                if (!f._fbq) f._fbq = n;
                n.push = n;
                n.loaded = true;
                n.version = '2.0';
                n.queue = [];
                t = b.createElement(e);
                t.async = true;
                t.src = v;
                s = b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t, s);
            })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

            window.fbq('init', '513960579627400'); // Use window.fbq instead of fbq
            window.fbq('track', 'HomePageView');   // Custom event tracking
        };

        loadMetaPixel();
    }, []); // Empty dependency array to ensure it runs once when component mounts

    const handleSpecificDocs = (doc) => {
        localStorage.setItem("docId",doc._id);
        navigate('/writer', { state: { doc  } });

    };

    const handleDelete = (doc) => {
        setDocToDelete(doc);
        setModalDelete(true);
    };

    return (
        <>
            {/* <Navbar /> */}

            <div className='home-parent-div hide-scroll'>
                <div className='add-doc-col'>
                    <div className="add-doc" onClick={() => setModalSave(true)}>
                        <img src={img1} alt="Add New Document" className='add-icon-logo' />
                      
                    </div>
                    <LinkedInLogin/>
                </div>
                <div className='documents-col'>
                    <div className='documents-row'>
                        {documents.length > 0 ? (
                            documents.map((doc) => (
                                <div key={doc._id} className="document">
                                    <div className="file-image-div" onClick={() => handleSpecificDocs(doc)}>
                                        <img src={img2} alt="File" className="file-thumbnail" />
                                    </div>
                                    <div className="file-info">
                                        <span className="file-title" onClick={() => handleSpecificDocs(doc)}>{doc.title}</span>
                                        <MdDelete className="delete-icon" onClick={() => handleDelete(doc)} />
                                    </div>
                                   
                                </div>
                            ))
                        ) : (
                            <> {/* <p>No documents available.</p> */} </>
                        )}
                    </div>
                </div>
                <ModalDoc modalSave={modalSave} setModalSave={setModalSave}  />
                <ModalDelete modalDelete={modalDelete} setModalDelete={setModalDelete} docToDelete={docToDelete} setDocToDelete={setDocToDelete} token={token}  fetchDocuments={fetchDocuments} />
            </div>
            
        </>
    );
};

export default HomePage;