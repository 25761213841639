
// *** This code uses custom formatting 

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Toolbar.css';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import { MdFormatListNumbered } from "react-icons/md";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListOl, faListUl } from '@fortawesome/free-solid-svg-icons';
import LinkedInModal from './LinkedInModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScheduleModal from './linkedin/ScheduleModal';
import { useDetectClickOutside } from "react-detect-click-outside";
const Toolbar = ({
  documentName,
  setDocumentName,
  editorContent,
  setEditorContent,
  docId,
  setModalSave,
  setDocId,
  quillRef
}) => {
  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(documentName || 'Select Document');
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isPostModalOpen,setPostModal]=useState(false)
  const token = localStorage.getItem('authToken');

  useEffect(() => {
    fetchDocuments();
  }, []);


  useEffect(() => {
    setSelectedDocument(documentName);
  }, [documentName]);

  const handleOptionClick = (doc) => {
    fetchDocuments();
    setSelectedDocument(doc.title);
    handleDocumentChange(doc);
    setIsOpen(false);
  };

  // const handleSaveDocument = async () => {
  //   setLoading(true); // Set loading to true
  //   try {
  //     const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/save-document`, {
  //       documentName: selectedDocument,
  //       content: editorContent,
  //       docId,
  //     },
  //       {
  //         headers: {
  //           'Authorization': `Bearer ${token}`,
  //           'Content-Type': 'application/json',
  //         },
  //         withCredentials: true,
  //       }
  //     );

  //     if (response.status === 200) {
  //       fetchDocuments();
  //       alert('Document saved successfully!');
  //     } else {
  //       alert('Failed to save document. Please try again.');
  //     }
  //   } catch (error) {
  //     // console.error('Error saving document:', error);
  //     alert('Error saving document.');
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const handleSaveDocument = async () => {
    setLoading(true); // Set loading to true
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/save-document`, {
          documentName: selectedDocument,
          content: editorContent,
          docId,
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );
  
      if (response.status === 200) {
        fetchDocuments();
        toast.success('Document saved successfully!', {
          style: {
            backgroundColor: '#0072FF',
            color: '#fff',
            fontWeight: 'bold',
            borderRadius: '8px', // Rounded corners
            padding: '16px', // More padding for better spacing
            boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)', // Subtle shadow for depth
          },
          hideProgressBar: true,
          icon: false, // Remove default icon
          bodyStyle: {
            fontWeight: 'bold',
            fontSize: '16px', // Larger text for emphasis
          },
          // Optional: unique ID for the toast
        });
        
      } else {
        toast.error('Failed to save document. Please try again.', {
          style: {
            backgroundColor: '#FF4D4D', // Bright red for errors
            color: '#fff',
            fontWeight: 'bold',
            borderRadius: '8px', // Rounded corners
            padding: '16px', // Adequate padding
            boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)', // Subtle shadow for depth
          },
          hideProgressBar: true,
          icon: false, // Remove default icon
          bodyStyle: {
            fontWeight: 'bold',
            fontSize: '16px', // Larger text for emphasis
          },
           // Optional: unique ID for the toast
        });
        
      }
    } catch (error) {
      toast.error('Error saving document.', {
        className: 'custom-toast', // Apply custom class here
      });
    } finally {
      setLoading(false);
    }
  };
  
  

  const fetchDocuments = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/user/documents`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to fetch documents');
      }

      const data = await response.json();
      setDocuments(data.documents);

      const storedId = localStorage.getItem("docId");
      if (storedId) {
        const foundDoc = (data.documents).find(doc => doc._id === storedId);
        // console.log("found doc",foundDoc);
        setSelectedDocument(foundDoc.title);
        handleDocumentChange(foundDoc);
      }
    } catch (error) {
      // console.error('Error fetching documents:', error);
    }
  };

  const handleDocumentChange = (selectedDoc) => {
    if (selectedDoc) {
      localStorage.setItem("docId", selectedDoc._id)
      setDocId(selectedDoc._id);
      setDocumentName(selectedDoc.title);
      setEditorContent(selectedDoc.content);
    }
  };

  const filteredDocuments = documents.filter(
    (doc) => doc.title !== selectedDocument
  );

  const boldDigits = { '0': '𝟬', '1': '𝟭', '2': '𝟮', '3': '𝟯', '4': '𝟰', '5': '𝟱', '6': '𝟲', '7': '𝟳', '8': '𝟴', '9': '𝟵' };

  const boldMap = {
    '0': '𝟬', '1': '𝟭', '2': '𝟮', '3': '𝟯', '4': '𝟰', '5': '𝟱', '6': '𝟲', '7': '𝟳', '8': '𝟴', '9': '𝟵',
    'a': '𝗮', 'b': '𝗯', 'c': '𝗰', 'd': '𝗱', 'e': '𝗲', 'f': '𝗳', 'g': '𝗴',
    'h': '𝗵', 'i': '𝗶', 'j': '𝗷', 'k': '𝗸', 'l': '𝗹', 'm': '𝗺', 'n': '𝗻',
    'o': '𝗼', 'p': '𝗽', 'q': '𝗾', 'r': '𝗿', 's': '𝘀', 't': '𝘁', 'u': '𝘂',
    'v': '𝘃', 'w': '𝘄', 'x': '𝘅', 'y': '𝘆', 'z': '𝘇',
    'A': '𝗔', 'B': '𝗕', 'C': '𝗖', 'D': '𝗗', 'E': '𝗘', 'F': '𝗙', 'G': '𝗚',
    'H': '𝗛', 'I': '𝗜', 'J': '𝗝', 'K': '𝗞', 'L': '𝗟', 'M': '𝗠', 'N': '𝗡',
    'O': '𝗢', 'P': '𝗣', 'Q': '𝗤', 'R': '𝗥', 'S': '𝗦', 'T': '𝗧', 'U': '𝗨',
    'V': '𝗩', 'W': '𝗪', 'X': '𝗫', 'Y': '𝗬', 'Z': '𝗭'
  };
  const reverseBoldMap2 = {
    '𝟬': '0', '𝟭': '1', '𝟮': '2', '𝟯': '3', '𝟰': '4', '𝟱': '5', '𝟲': '6', '𝟳': '7', '𝟴': '8', '𝟵': '9',
    '𝗮': 'a', '𝗯': 'b', '𝗰': 'c', '𝗱': 'd', '𝗲': 'e', '𝗳': 'f', '𝗴': 'g',
    '𝗵': 'h', '𝗶': 'i', '𝗷': 'j', '𝗸': 'k', '𝗹': 'l', '𝗺': 'm', '𝗻': 'n',
    '𝗼': 'o', '𝗽': 'p', '𝗾': 'q', '𝗿': 'r', '𝘀': 's', '𝘁': 't', '𝘂': 'u',
    '𝘃': 'v', '𝘄': 'w', '𝘅': 'x', '𝘆': 'y', '𝘇': 'z',
    '𝗔': 'A', '𝗕': 'B', '𝗖': 'C', '𝗗': 'D', '𝗘': 'E', '𝗙': 'F', '𝗚': 'G',
    '𝗛': 'H', '𝗜': 'I', '𝗝': 'J', '𝗞': 'K', '𝗟': 'L', '𝗠': 'M', '𝗡': 'N',
    '𝗢': 'O', '𝗣': 'P', '𝗤': 'Q', '𝗥': 'R', '𝗦': 'S', '𝗧': 'T', '𝗨': 'U',
    '𝗩': 'V', '𝗪': 'W', '𝗫': 'X', '𝗬': 'Y', '𝗭': 'Z'
  };
  const reverseBoldMap = {
    57324: '0', 57325: '1', 57326: '2', 57327: '3', 57328: '4', 57329: '5', 57330: '6', 57331: '7', 57332: '8', 57333: '9',
    56814: 'a', 56815: 'b', 56816: 'c', 56817: 'd', 56818: 'e', 56819: 'f', 56820: 'g',
    56821: 'h', 56822: 'i', 56823: 'j', 56824: 'k', 56825: 'l', 56826: 'm', 56827: 'n',
    56828: 'o', 56829: 'p', 56830: 'q', 56831: 'r', 56832: 's', 56833: 't', 56834: 'u',
    56835: 'v', 56836: 'w', 56837: 'x', 56838: 'y', 56839: 'z',
    56788: 'A', 56789: 'B', 56790: 'C', 56791: 'D', 56792: 'E', 56793: 'F', 56794: 'G',
    56795: 'H', 56796: 'I', 56797: 'J', 56798: 'K', 56799: 'L', 56800: 'M', 56801: 'N',
    56802: 'O', 56803: 'P', 56804: 'Q', 56805: 'R', 56806: 'S', 56807: 'T', 56808: 'U',
    56809: 'V', 56810: 'W', 56811: 'X', 56812: 'Y', 56813: 'Z'
  };

  const reverseItalicMap = {
    56398: 'a', 56399: 'b', 56400: 'c', 56401: 'd', 56402: 'e', 56403: 'f', 56404: 'g',
    56405: 'h', 56406: 'i', 56407: 'j', 56408: 'k', 56409: 'l', 56410: 'm', 56411: 'n',
    56412: 'o', 56413: 'p', 56414: 'q', 56415: 'r', 56416: 's', 56417: 't', 56418: 'u',
    56419: 'v', 56420: 'w', 56421: 'x', 56422: 'y', 56423: 'z',
    56372: 'A', 56373: 'B', 56374: 'C', 56375: 'D', 56376: 'E', 56377: 'F', 56378: 'G',
    56379: 'H', 56380: 'I', 56381: 'J', 56382: 'K', 56383: 'L', 56384: 'M', 56385: 'N',
    56386: 'O', 56387: 'P', 56388: 'Q', 56389: 'R', 56390: 'S', 56391: 'T', 56392: 'U',
    56393: 'V', 56394: 'W', 56395: 'X', 56396: 'Y', 56397: 'Z'
  };
  const underlineCharMap = {
    'a': '𝚊̲', 'b': '𝚋̲', 'c': '𝚌̲', 'd': '𝚍̲', 'e': '𝚎̲', 'f': '𝚏̲', 'g': '𝚐̲', 'h': '𝚑̲', 'i': '𝚒̲', 'j': '𝚓̲',
    'k': '𝚔̲', 'l': '𝚕̲', 'm': '𝚖̲', 'n': '𝚗̲', 'o': '𝚘̲', 'p': '𝚙̲', 'q': '𝚚̲', 'r': '𝚛̲', 's': '𝚜̲', 't': '𝚝̲',
    'u': '𝚞̲', 'v': '𝚟̲', 'w': '𝚠̲', 'x': '𝚡̲', 'y': '𝚢̲', 'z': '𝚣̲',
    'A': '𝙰̲', 'B': '𝙱̲', 'C': '𝙲̲', 'D': '𝙳̲', 'E': '𝙴̲', 'F': '𝙵̲', 'G': '𝙶̲', 'H': '𝙷̲', 'I': '𝙸̲', 'J': '𝙹̲',
    'K': '𝙺̲', 'L': '𝙻̲', 'M': '𝙼̲', 'N': '𝙽̲', 'O': '𝙾̲', 'P': '𝙿̲', 'Q': '𝚀̲', 'R': '𝚁̲', 'S': '𝚂̲', 'T': '𝚃̲',
    'U': '𝚄̲', 'V': '𝚅̲', 'W': '𝚆̲', 'X': '𝚇̲', 'Y': '𝚈̲', 'Z': '𝚉̲',
    '0': '𝟶̲', '1': '𝟷̲', '2': '𝟸̲', '3': '𝟹̲', '4': '𝟺̲', '5': '𝟻̲', '6': '𝟼̲', '7': '𝟽̲', '8': '𝟾̲', '9': '𝟿̲',
    '!': '!̲', '@': '@̲', '#': '#̲', '$': '$̲', '%': '%̲', '^': '^̲', '&': '&̲', '*': '*̲', '(': '(̲', ')': ')̲',
    '-': '–̲', '_': '_̲', '=': '=̲', '+': '+̲', '[': '[̲', ']': ']̲', '{': '{̲', '}': '}̲', '|': '|̲',
    ';': ';̲', ':': ':̲', '\'': '\'̲', '"': '"̲', ',': ',̲', '.': '.̲', '<': '<̲', '>': '>̲', '?': '?̲',
    '/': '/̲', '`': '`̲', '~': '~̲', '\\': '\\̲'
  };
  const reverseUnderlineCharMap = {
    '𝚊̲': 'a', '𝚋̲': 'b', '𝚌̲': 'c', '𝚍̲': 'd', '𝚎̲': 'e', '𝚏̲': 'f', '𝚐̲': 'g', '𝚑̲': 'h', '𝚒̲': 'i', '𝚓̲': 'j',
    '𝚔̲': 'k', '𝚕̲': 'l', '𝚖̲': 'm', '𝚗̲': 'n', '𝚘̲': 'o', '𝚙̲': 'p', '𝚚̲': 'q', '𝚛̲': 'r', '𝚜̲': 's', '𝚝̲': 't',
    '𝚞̲': 'u', '𝚟̲': 'v', '𝚠̲': 'w', '𝚡̲': 'x', '𝚢̲': 'y', '𝚣̲': 'z',
    '𝙰̲': 'A', '𝙱̲': 'B', '𝙲̲': 'C', '𝙳̲': 'D', '𝙴̲': 'E', '𝙵̲': 'F', '𝙶̲': 'G', '𝙷̲': 'H', '𝙸̲': 'I', '𝙹̲': 'J',
    '𝙺̲': 'K', '𝙻̲': 'L', '𝙼̲': 'M', '𝙽̲': 'N', '𝙾̲': 'O', '𝙿̲': 'P', '𝚀̲': 'Q', '𝚁̲': 'R', '𝚂̲': 'S', '𝚃̲': 'T',
    '𝚄̲': 'U', '𝚅̲': 'V', '𝚆̲': 'W', '𝚇̲': 'X', '𝚈̲': 'Y', '𝚉̲': 'Z',
    '𝟶̲': '0', '𝟷̲': '1', '𝟸̲': '2', '𝟹̲': '3', '𝟺̲': '4', '𝟻̲': '5', '𝟼̲': '6', '𝟽̲': '7', '𝟾̲': '8', '𝟿̲': '9',
    '!̲': '!', '@̲': '@', '#̲': '#', '$̲': '$', '%̲': '%', '^̲': '^', '&̲': '&', '*̲': '*', '(̲': '(', ')̲': ')',
    '–̲': '-', '_̲': '_', '=̲': '=', '+̲': '+', '[̲': '[', ']̲': ']', '{̲': '{', '}̲': '}', '|̲': '|',
    ';̲': ';', ':̲': ':', '\'̲': '\'', '"̲': '"', ',̲': ',', '.̲': '.', '<̲': '<', '>̲': '>', '?̲': '?',
    '/̲': '/', '`̲': '`', '~̲': '~', '\\̲': '\\'
  };
  const strikethroughMap = {
    'a': '𝚊̶', 'b': '𝚋̶', 'c': '𝚌̶', 'd': '𝚍̶', 'e': '𝚎̶', 'f': '𝚏̶', 'g': '𝚐̶',
    'h': '𝚑̶', 'i': '𝚒̶', 'j': '𝚓̶', 'k': '𝚔̶', 'l': '𝚕̶', 'm': '𝚖̶', 'n': '𝚗̶',
    'o': '𝚘̶', 'p': '𝚙̶', 'q': '𝚚̶', 'r': '𝚛̶', 's': '𝚜̶', 't': '𝚝̶', 'u': '𝚞̶',
    'v': '𝚟̶', 'w': '𝚠̶', 'x': '𝚡̶', 'y': '𝚢̶', 'z': '𝚣̶',
    'A': '𝙰̶', 'B': '𝙱̶', 'C': '𝙲̶', 'D': '𝙳̶', 'E': '𝙴̶', 'F': '𝙵̶', 'G': '𝙶̶',
    'H': '𝙷̶', 'I': '𝙸̶', 'J': '𝙹̶', 'K': '𝙺̶', 'L': '𝙻̶', 'M': '𝙼̶', 'N': '𝙽̶',
    'O': '𝙾̶', 'P': '𝙿̶', 'Q': '𝚀̶', 'R': '𝚁̶', 'S': '𝚂̶', 'T': '𝚃̶', 'U': '𝚄̶',
    'V': '𝚅̶', 'W': '𝚆̶', 'X': '𝚇̶', 'Y': '𝚈̶', 'Z': '𝚉̶',
    '0': '𝟶̶', '1': '𝟷̶', '2': '𝟸̶', '3': '𝟹̶', '4': '𝟺̶', '5': '𝟻̶', '6': '𝟼̶',
    '7': '𝟽̶', '8': '𝟾̶', '9': '𝟿̶',
    '`': '`̶', '~': '~̶', '!': '!̶', '@': '@̶', '#': '#̶', '$': '$̶', '%': '%̶',
    '^': '^̶', '&': '&̶', '*': '*̶', '(': '(̶', ')': ')̶', '-': '–̶', '_': '_̶',
    '=': '=̶', '+': '+̶', '[': '[̶', '{': '{̶', ']': ']̶', '}': '}̶', '\\': '\\̶',
    '|': '|̶', ';': ';̶', ':': ':̶', '\'': '\'̶', '"': '"̶', ',': ',̶', '<': '<̶',
    '.': '.̶', '>': '>̶', '/': '/̶', '?': '?̶', ' ': ' ̶'
  };

  const reverseStrikethroughMap = {
    '𝚊̶': 'a', '𝚋̶': 'b', '𝚌̶': 'c', '𝚍̶': 'd', '𝚎̶': 'e', '𝚏̶': 'f', '𝚐̶': 'g',
    '𝚑̶': 'h', '𝚒̶': 'i', '𝚓̶': 'j', '𝚔̶': 'k', '𝚕̶': 'l', '𝚖̶': 'm', '𝚗̶': 'n',
    '𝚘̶': 'o', '𝚙̶': 'p', '𝚚̶': 'q', '𝚛̶': 'r', '𝚜̶': 's', '𝚝̶': 't', '𝚞̶': 'u',
    '𝚟̶': 'v', '𝚠̶': 'w', '𝚡̶': 'x', '𝚢̶': 'y', '𝚣̶': 'z',
    '𝙰̶': 'A', '𝙱̶': 'B', '𝙲̶': 'C', '𝙳̶': 'D', '𝙴̶': 'E', '𝙵̶': 'F', '𝙶̶': 'G',
    '𝙷̶': 'H', '𝙸̶': 'I', '𝙹̶': 'J', '𝙺̶': 'K', '𝙻̶': 'L', '𝙼̶': 'M', '𝙽̶': 'N',
    '𝙾̶': 'O', '𝙿̶': 'P', '𝚀̶': 'Q', '𝚁̶': 'R', '𝚂̶': 'S', '𝚃̶': 'T', '𝚄̶': 'U',
    '𝚅̶': 'V', '𝚆̶': 'W', '𝚇̶': 'X', '𝚈̶': 'Y', '𝚉̶': 'Z',
    '𝟶̶': '0', '𝟷̶': '1', '𝟸̶': '2', '𝟹̶': '3', '𝟺̶': '4', '𝟻̶': '5', '𝟼̶': '6',
    '𝟽̶': '7', '𝟾̶': '8', '𝟿̶': '9',
    '`̶': '`', '~̶': '~', '!̶': '!', '@̶': '@', '#̶': '#', '$̶': '$', '%̶': '%',
    '^̶': '^', '&̶': '&', '*̶': '*', '(̶': '(', ')̶': ')', '–̶': '-', '_̶': '_',
    '=̶': '=', '+̶': '+', '[̶': '[', '{̶': '{', ']̶': ']', '}̶': '}', '\\̶': '\\',
    '|̶': '|', ';̶': ';', ':̶': ':', '\'̶': '\'', '"̶': '"', ',̶': ',', '<̶': '<',
    '.̶': '.', '>̶': '>', '/̶': '/', '?̶': '?', ' ̶': ' '
  };
  const reverseStrike1 = {
    56970: 'a', 56971: 'b', '𝚌': 'c', '𝚍': 'd', '𝚎': 'e', '𝚏': 'f', '𝚐': 'g',
    '𝚑': 'h', '𝚒': 'i', '𝚓': 'j', '𝚔': 'k', '𝚕': 'l', '𝚖': 'm', '𝚗': 'n',
    '𝚘': 'o', '𝚙': 'p', '𝚚': 'q', '𝚛': 'r', '𝚜': 's', '𝚝': 't', '𝚞': 'u',
    '𝚟': 'v', '𝚠': 'w', '𝚡': 'x', 56954: 'y', 56955: 'z',
    56944: 'A', 56945: 'B', '𝙲': 'C', '𝙳': 'D', '𝙴': 'E', '𝙵': 'F', '𝙶': 'G',
    '𝙷': 'H', '𝙸': 'I', '𝙹': 'J', '𝙺': 'K', '𝙻': 'L', '𝙼': 'M', '𝙽': 'N',
    '𝙾': 'O', '𝙿': 'P', '𝚀': 'Q', '𝚁': 'R', '𝚂': 'S', '𝚃': 'T', '𝚄': 'U',
    '𝚅': 'V', '𝚆': 'W', '𝚇': 'X', 56968: 'Y', 56969: 'Z',
    57334: '0', 57335: '1', '𝟸': '2', '𝟹': '3', '𝟺': '4', '𝟻': '5', '𝟼': '6',
    '𝟽': '7', '𝟾': '8', 57343: '9',
    '`': '`', '~': '~', '!': '!', '@': '@', '#': '#', '$': '$', '%': '%',
    '^': '^', '&': '&', '*': '*', '(': '(', ')': ')', '-': '-', '_': '_',
    '=': '=', '+': '+', '[': '[', '{': '{', ']': ']', '}': '}', '\\': '\\',
    '|': '|', ';': ';', ':': ':', '\'': '\'', '"': '"', ',': ',', '<': '<',
    '.': '.', '>': '>', '/': '/', '?': '?'
  };
  const reverseStrike = {
    56970: 'a', 56971: 'b', 56972: 'c', 56973: 'd', 56974: 'e', 56975: 'f', 56976: 'g',
    56977: 'h', 56978: 'i', 56979: 'j', 56980: 'k', 56981: 'l', 56982: 'm', 56983: 'n',
    56984: 'o', 56985: 'p', 56986: 'q', 56987: 'r', 56988: 's', 56989: 't', 56990: 'u',
    56991: 'v', 56992: 'w', 56993: 'x', 56994: 'y', 56995: 'z',
    56944: 'A', 56945: 'B', 56946: 'C', 56947: 'D', 56948: 'E', 56949: 'F', 56950: 'G',
    56951: 'H', 56952: 'I', 56953: 'J', 56954: 'K', 56955: 'L', 56956: 'M', 56957: 'N',
    56958: 'O', 56959: 'P', 56960: 'Q', 56961: 'R', 56962: 'S', 56963: 'T', 56964: 'U',
    56965: 'V', 56966: 'W', 56967: 'X', 56968: 'Y', 56969: 'Z',
    57334: '0', 57335: '1', 57336: '2', 57337: '3', 57338: '4', 57339: '5', 57340: '6',
    57341: '7', 57342: '8', 57343: '9',
    '`': '`', '~': '~', '!': '!', '@': '@', '#': '#', '$': '$', '%': '%',
    '^': '^', '&': '&', '*': '*', '(': '(', ')': ')', '-': '-', '_': '_',
    '=': '=', '+': '+', '[': '[', '{': '{', ']': ']', '}': '}', '\\': '\\',
    '|': '|', ';': ';', ':': ':', '\'': '\'', '"': '"', ',': ',', '<': '<',
    '.': '.', '>': '>', '/': '/', '?': '?'
  };


  function translateBold(char) {
    let diff;
    if (/[0-9]/.test(char)) {
      return boldDigits[char];
    } else if (/[A-Z]/.test(char)) {
      diff = "𝗔".codePointAt(0) - "A".codePointAt(0);
    } else if (/[a-z]/.test(char)) {
      diff = "𝗮".codePointAt(0) - "a".codePointAt(0);
    } else {
      return char;
    }
    return String.fromCodePoint(char.codePointAt(0) + diff);
  }

  const handleBoldText = () => {
    const editor = quillRef.current.getEditor();
    const range = editor.getSelection();
    if (range) {
      const selectedText = editor.getText(range.index, range.length);
      const translatedText = selectedText.split('').map(char => {
        const charCode = char.codePointAt(0);

        // console.log(`Char: ${char}, Code Point: ${charCode}`);

        if (charCode === 55349 || charCode === 822) {
          return;
        }
        else if (charCode === 8462) {
          const ch = 'h';
          return translateBold(ch);
        }
        else if (char === ' ' || char === '') {
          return char;
        }
        else if ((charCode >= 56372 && charCode <= 56423)) {
          const ch = reverseItalicMap[charCode];
          return translateBold(ch);
        }
        else if ((charCode >= 56944 && charCode <= 56995) || (charCode >= 57334 && charCode <= 57343)) {
          const ch = reverseStrike[charCode];
          return translateBold(ch);
        }
        else if (reverseBoldMap[charCode]) {
          const normalChar = reverseBoldMap[charCode];
          // const asciiValue = normalChar.charCodeAt(0);
          // console.log(`Inside unbold block: Bold Char - ${char}, Normal Char - ${normalChar}, ASCII - ${asciiValue}`);
          return normalChar;
        } else {
          // console.log('inside else', char, `Code Point: ${charCode}`);
          return translateBold(char);
        }
      }).join('');
      editor.deleteText(range.index, range.length);
      editor.insertText(range.index, translatedText);
      editor.setSelection(range.index, translatedText.length);
    }
  };

  function translateItalic(char) {
    const charCode = char.codePointAt(0);
    // console.log(char,charCode);
    if (charCode === 55349 || charCode === 822) {
      return;
    }
    if (charCode === 8462) {
      return 'h';
    }
    if (charCode >= 56372 && charCode <= 56423) {
      return reverseItalicMap[charCode];
    }
    if ((charCode >= 57324 && charCode <= 57333) || (charCode >= 56814 && charCode <= 56839) || (charCode >= 56788 && charCode <= 56813)) {
      char = reverseBoldMap[charCode];
    }
    if ((charCode >= 56944 && charCode <= 56995) || (charCode >= 57334 && charCode <= 57343)) {
      char = reverseStrike[charCode];
    }
    if (/[A-Za-z]/.test(char)) {
      if (char === 'h') {
        return 'ℎ';
      }
      let diff;
      if (/[A-Z]/.test(char)) {
        diff = "𝐴".codePointAt(0) - "A".codePointAt(0);
      } else {
        diff = "𝑎".codePointAt(0) - "a".codePointAt(0);
      }
      return String.fromCodePoint(char.codePointAt(0) + diff);
    }
    return char;
  }

  const customItalicHandler = () => {
    const editor = quillRef.current.getEditor();
    const selection = editor.getSelection();
    if (selection && selection.length > 0) {
      const selectedText = editor.getText(selection.index, selection.length);
      const italicText = selectedText.split('').map(translateItalic).join('');
      editor.deleteText(selection.index, selection.length);
      editor.insertText(selection.index, italicText);
      editor.setSelection(selection.index, italicText.length);

      // ******* to apply default formatting
      // const newRange = {
      //   index: selection.index,
      //   length: italicText.length
      // };
      // const currentFormat = editor.getFormat(newRange);
      // editor.formatText(newRange.index, newRange.length, 'italic', !currentFormat.italic);
    }
  };

  function translateUnderline(char, index, arr) {
    const unicodeChar = String.fromCodePoint(818);
    const nextChar = arr[index + 1];
    const charCode = char.codePointAt(0);
    // console.log(char,nextChar, char.codePointAt(0));
    if (charCode === 55349 || charCode === 822) {
      return;
    }
    if (charCode === 8462) {
      char = 'h';
    }
    if ((charCode >= 56372 && charCode <= 56423)) {
      char = reverseItalicMap[charCode];
    }
    if (reverseBoldMap[charCode]) {
      char = reverseBoldMap[charCode];
    }
    if ((charCode >= 56944 && charCode <= 56995) || (charCode >= 57334 && charCode <= 57343)) {
      char = reverseStrike[charCode];
    }
    if (char === unicodeChar) return;
    if (nextChar === unicodeChar) {
      // console.log('Already underlined:', char);
      return char;
    }
    if (/[A-Za-z0-9]/.test(char)) {
      // console.log('Adding underline to:', char);
      return char + unicodeChar;
    }
    return char + unicodeChar;
  }

  const customUnderlineHandler = () => {
    const editor = quillRef.current.getEditor();
    const selection = editor.getSelection();
    if (selection && selection.length > 0) {
      const selectedText = editor.getText(selection.index, selection.length);
      const underlineText = selectedText.split('').map(translateUnderline).join('');
      editor.deleteText(selection.index, selection.length);
      editor.insertText(selection.index, underlineText);
      editor.setSelection(selection.index, underlineText.length);
    }
  };

  function translateStrike(char, index, arr) {
    const unicodeChar = String.fromCodePoint(822);
    const charCode = char.codePointAt(0)
    const nextChar = arr[index + 1];
    // console.log(char,nextChar, char.codePointAt(0));
    if (charCode === 8462) {
      char = 'h';
    }
    if (char === ' ' || char === '') {
      return char;
    }
    if ((charCode >= 56372 && charCode <= 56423)) {
      char = reverseItalicMap[charCode];
    }
    if ((charCode >= 57324 && charCode <= 57333) || (charCode >= 56814 && charCode <= 56839) || (charCode >= 56788 && charCode <= 56813)) {
      char = reverseBoldMap[charCode];
    }
    if (char === unicodeChar || charCode === 55349) return;
    else if (nextChar === unicodeChar && ((charCode >= 56944 && charCode <= 56995) || (charCode >= 57334 && charCode <= 57343))) {
      // console.log('Already underlined:', char);
      return reverseStrike[charCode];
    }
    if (/[A-Za-z0-9]/.test(char))
    // else
    {
      // console.log('Adding underline to:', char);
      // return char + unicodeChar;
      // return char + '\u0336';
      return strikethroughMap[char];
    }
    return char;
  }
  const postDocument = async (editorContent) => {
    const userConfirmed = window.confirm('Do you really want to post this document?');
    if (!userConfirmed) {
        return; // Exit the function if the user cancels
    }
    console.log('Document to be posted:', editorContent);
    try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/postToLinkedIn`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`, // Ensure `token` is defined
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ content: editorContent }),
        });

        if (!response.ok) {
            // Handle HTTP errors
            const errorMessage = await response.text(); // or response.json() if your backend returns JSON errors
            throw new Error(`Error: ${response.status} - ${errorMessage}`);
        }

        const result = await response.json();
        alert('Document posted successfully: ' + JSON.stringify(result));
        // Optionally update state or handle the response
    } catch (error) {
        console.error(error);
        alert('Failed to post document: ' + error.message);
    }
};


  const customStrikeHandler = () => {
    const editor = quillRef.current.getEditor();
    const selection = editor.getSelection();
    if (selection && selection.length > 0) {
      const selectedText = editor.getText(selection.index, selection.length);
      const strikeText = selectedText.split('').map(translateStrike).join('');
      editor.deleteText(selection.index, selection.length);
      editor.insertText(selection.index, strikeText);
      editor.setSelection(selection.index, strikeText.length);
    }
  };

  const customBulletHandler = () => {
    // console.log('checking bullet handler');
    if (quillRef && quillRef.current) {
      const quill = quillRef.current.getEditor();
      const range = quill.getSelection();

      if (range) {
        const selectedText = quill.getText(range.index, range.length);
        const lines = selectedText.split('\n');
        quill.deleteText(range.index, range.length);
        let formattedText = lines.map(line => {
          // return line.trim().startsWith('•') ? line : `• ${line}`;
          return `• ${line}`;
        }).join('\n');
        quill.insertText(range.index, formattedText);
        quill.setSelection(range.index, formattedText.length);
      }
    }
  };

  const customListHandler = () => {
    if (quillRef && quillRef.current) {
      const quill = quillRef.current.getEditor();
      const range = quill.getSelection();

      if (range) {
        const selectedText = quill.getText(range.index, range.length);
        console.log('0', selectedText);
        const lines = selectedText.split('\n');
        quill.deleteText(range.index, range.length);

        let index = 0;
        let formattedText = lines.map(line => {
          if (line.trim() === '') return '';

          let cleanedLine = line.trim().replace(/^\s*/, '');

          if (/^\d+\.\s/.test(cleanedLine)) return cleanedLine;

          index += 1;
          return `${index}.  ${cleanedLine}`;
        }).join('\n');
        // console.log(formattedText)
        quill.insertText(range.index, formattedText);
        quill.setSelection(range.index, formattedText.length);

      }
    }
  };
  const closeDropdown = () => {
    setIsOpen(false);
  };
  const ref = useDetectClickOutside({ onTriggered: closeDropdown });

  const createDocument = () => {
    setModalSave(true);
  }

  return (
    <div id="toolbar">
     <ToastContainer 
      position="top-center" 
      autoClose={1000} 
      hideProgressBar={false} 
      newestOnTop={false} 
      closeOnClick 
      rtl={false} 
      pauseOnFocusLoss 
      draggable 
      pauseOnHover 
      toastClassName="custom-toast" // Apply custom class here
    />

<div className="custom-select-container" style={{ position: 'relative', zIndex: 100 }}>
        <div className="custom-select-header" 
        ref={ref}>
          <input
            type='text'
            value={selectedDocument}
            onChange={(e) => setSelectedDocument(e.target.value)}
            placeholder='Select the document' />
          <span className={`dropdown-arrow ${isOpen ? 'up' : 'down'}`} onClick={()=>{setIsOpen(!isOpen)}}></span>
        </div>

        {isOpen && (
          <div className="custom-select-dropdown hide-scroll">
            <div className="custom-select-item" onClick={createDocument} > Create document </div>
            {filteredDocuments.length > 0 ? (
              filteredDocuments.map((doc) => (
                <div key={doc._id} className="custom-select-item" onClick={() => handleOptionClick(doc)}>
                  {doc.title}
                </div>
              ))
            ) : (
              {/* <div className="custom-select-item">No other documents</div> */ }
            )}
          </div>
        )}
      </div>
      <div className='vr-line'></div>

      <div className="ql-dashboard effects" onClick={() => navigate('/dashboard')}>Dashboard</div>

      <div className='vr-line'></div>
      {/* <div className="ql-dashboard effects" onClick={() => postDocument(editorContent)}>
    POST
</div> */}
<div className="ql-dashboard effects" onClick={() => setModalOpen(true)}>
                Post
            </div>
           

            <div
      className="ql-dashboard effects"
      onClick={() => setPostModal(true)}
    >
      Schedule Post
    </div>

    {/* Schedule Modal */}
    <ScheduleModal isOpen={isPostModalOpen} onClose={() => setPostModal(false)} docId={docId}/>
  
  {/* <ScheduleModal isPostModalOpen={isPostModalOpen} onClose={() => setPostModal(false)} /> */}
      <button className="ql-save effects" onClick={loading ? null : handleSaveDocument}>
        {loading ? <Spinner animation="border" size="sm" /> : <>Save</>}
      </button>

      <div className='vr-line'></div>

      <button className="ql-bold btn" onClick={handleBoldText}>Bold</button>
      {/* <button className="q1-bold btn" >Bold</button>  */}
      <button className="ql-italic btn" onClick={customItalicHandler}>Italic</button>
      {/* <button className="q1-italic btn" >Italic</button> */}
      <button className="ql-underline btn" onClick={customUnderlineHandler}>U</button>
      {/* <button className="ql-underline btn">Underline</button> */}
      <button className="ql-strike btn" onClick={customStrikeHandler}>Strike</button>
      {/* <button className="ql-strike btn">Strike</button>  */}
      {/* <button className="ql-blockquote btn">Blockquote</button> */}

      <div className='vr-line'></div>

      <button className="btn" onClick={customListHandler}><FontAwesomeIcon className='list-icon' icon={faListOl} /></button>
      {/* <button className="ql-list btn" value="ordered">Ordered List</button>  */}
      <button className="btn" onClick={customBulletHandler}> <FontAwesomeIcon className='list-icon' icon={faListUl} /></button>
      {/* <button className="ql-list btn" value="bullet">Bullet List</button> */}

      {/* <div className='vertical-line'></div> */}

      {/* <button className="ql-image btn" >Insert Image</button> */}
      
      <LinkedInModal
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false) } editorContent={editorContent}
                docId={docId}/>
    </div>
  );
};

export default Toolbar;